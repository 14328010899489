import { AnimatedTitle } from '@/components/UI/AnimatedTitle'
import { Container } from '@/components/UI/Container'
import { FC, useRef, useLayoutEffect } from 'react'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { Title } from '@/components/UI/TitleCore'
import { GradientCard } from '@/components/UI/Card'
import cx from 'classnames'
import { Anchor } from '@/components/UI/Anchor'
import { BackgroundPipeProps } from '@/types/blocks'
import { BackgroundPipes } from '@/components/UI/BackgroundPipes'
import { useShareState } from '@/context'
import useIntersectionObserver from '@/utils/useIntersectionObserver'

interface INewsCardProps {
	node: {
		cardDisplayImage: {
			gatsbyImageData: IGatsbyImageData
			alt: string
		}
		slug: string
		title: string
		category: {
			categoryName: string
			categoryColour: string
		}
	}
}

interface IRelatedNewsProps {
	relatedPosts: {
		edges: INewsCardProps[]
	}
}

export const RelatedNews: FC<IRelatedNewsProps> = ({
	relatedPosts: { edges = [] },
}) => {
	const backgroundPipe: BackgroundPipeProps = {
		invertPipeGradient: false,
		opacityMask: true,
		pipe: `pipe-ten`,
		pipeColour: `gradient-sky`,
	}
	const ref = useRef<HTMLDivElement | null>(null)
	const entry = useIntersectionObserver(ref, { threshold: 0.29 })
	const isVisible = !!entry?.isIntersecting
	const { setShow } = useShareState()

	useLayoutEffect(() => {
		if (isVisible) {
			setShow((prevState) => {
				return {
					...prevState,
					display: false,
				}
			})
		}

		return () => {
			setShow((prevState) => {
				return {
					...prevState,
					display: true,
				}
			})
		}
	}, [isVisible, setShow])
	return (
		<div className="text-white relative z-20 py-20 bg-primary-slate" ref={ref}>
			<Container className="z-20 relative">
				<div className="lg:my-8">
					<AnimatedTitle.Large variant="h1">Related News</AnimatedTitle.Large>
				</div>

				<div className="grid lg:grid-cols-3 gap-10 xl:gap-20 my-20 xl:my-28">
					{edges.length > 0
						? edges.map(
								({ node: { cardDisplayImage, slug, title, category } }, _index) => {
									const _image = cardDisplayImage
										? getImage(cardDisplayImage.gatsbyImageData)
										: null

									return (
										<Link to={`/news/${slug}`} key={slug}>
											<div className="relative p-4 xl:p-6 flex flex-col gap-6 text-white h-full">
												{_image ? (
													<GatsbyImage
														image={_image}
														alt={cardDisplayImage.alt || `alt text`}
														className="rounded-xl drop-shadow-[0_15px_15px_rgba(0,0,0,0.4)]"
													/>
												) : null}
												<Title.Small variant="h3" className="!font-bold">
													{title}
												</Title.Small>
												<div className="flex flex-row flex-nowrap gap-4">
													<Title.ExtraSmall
														variant="span"
														className={cx(
															`uppercase w-fit border-2 py-1 px-3 rounded-lg`,
															category.categoryColour
														)}
													>
														{category.categoryName}
													</Title.ExtraSmall>
												</div>
												<GradientCard className="bg-primary-slate-300" clip="top-right" />
											</div>
										</Link>
									)
								}
						  )
						: null}
				</div>

				<Anchor.Button
					to={`/news`}
					withArrow
					className="mx-auto w-fit bg-gradient-light-sky btn !text-primary-slate"
				>
					See All News
				</Anchor.Button>
			</Container>
			<BackgroundPipes {...{ ...backgroundPipe }} />
		</div>
	)
}
