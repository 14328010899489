import Blades from '@/components/BladeResolver'
import { Newsletter } from '@/components/Blocks/Newsletter'
import { RelatedNews } from '@/components/Blocks/RelatedNews'
import { AuthorBanner } from '@/components/UI/AuthorBanner'
import { NewsButtons } from '@/components/UI/NewsButtons'
import { NewsShareBox } from '@/components/UI/NewsShareBox'
import { useMenuState, useShareState } from '@/context'
import { Meta } from '@/globals/Meta'
import { Layout } from '@/hoc/Layout'
import { graphql } from 'gatsby'
import { FC, useEffect } from 'react'

const NewsPostTemplate: FC = ({ data, location }) => {
	const news = data?.news
	const seo = news?.metadata
	const meta = news?.meta
	const blades = news?.blades
	const author = news?.author
	const share = news?.sharePost
	const relatedPosts = data?.related

	const { setMenuOpen } = useMenuState()

	useEffect(() => {
		setMenuOpen(false)

		return () => {
			setMenuOpen(false)
		}
	}, [setMenuOpen])
	return (
		<Layout locale={`en`}>
			<Meta {...{ seo }} />

			<Blades {...{ blades }} />
			<NewsShareBox
				{...{
					location,
					share,
					title: news.title,
				}}
			/>
			{share ? (
				<NewsButtons
					{...{
						slug: news.slug,
						location,
						title: news.title,
						share,
					}}
				/>
			) : null}
			{author ? (
				<AuthorBanner {...{ ...author, publishedAt: meta?.firstPublishedAt }} />
			) : null}
			{relatedPosts.edges.length > 0 ? (
				<RelatedNews {...{ relatedPosts }} />
			) : null}
			{news.newsletterBlock[0] ? (
				<Newsletter
					{...{
						backgroundImage: news.newsletterBlock[0].backgroundImage,
						body: news.newsletterBlock[0].body,
						errorMessage: news.newsletterBlock[0].errorMessage,
						gdprLabel: news.newsletterBlock[0].gdprLabel,
						id: news.newsletterBlock[0].id,
						optInLabel: news.newsletterBlock[0].optInLabel,
						submitButtonMessage: news.newsletterBlock[0].posts,
						successMessage: news.newsletterBlock[0].successMessage,
						title: news.newsletterBlock[0].title,
						linkColour: news.newsletterBlock[0].linkColour,
						textColour: news.newsletterBlock[0].textColour,
						legalPassage: news.newsletterBlock[0].legalPassage,
					}}
				/>
			) : null}
		</Layout>
	)
}

export default NewsPostTemplate

export const query = graphql`
	query getNewsPage($id: String, $categoryName: String) {
		news: datoCmsNews(id: { eq: $id }) {
			id
			__typename
			slug
			title
			sharePost
			meta {
				firstPublishedAt(formatString: "DD MMMM, YYYY")
			}
			metadata {
				description
				title
				twitterCard
				image {
					url
				}
			}
			author {
				name
				roleTitle
				authorBio
				image {
					gatsbyImageData(placeholder: BLURRED, width: 125, height: 125)
					alt
				}
			}
			newsletterBackground {
				gatsbyImageData(placeholder: BLURRED)
				alt
			}
			newsletterBlock {
				__typename
				id
				title
				body
				backgroundImage {
					gatsbyImageData(placeholder: BLURRED)
					alt
				}
				errorMessage
				gdprLabel
				optInLabel
				submitButtonMessage
				successMessage
				legalPassage
				linkColour
				textColour
			}
			blades {
				... on DatoCmsHero {
					...Hero
				}
				... on DatoCmsNewsWrapper {
					...NewsWrapper
				}
			}
		}
		related: allDatoCmsNews(
			filter: { category: { categoryName: { eq: $categoryName } } }
			sort: { order: DESC, fields: meta___firstPublishedAt }
			limit: 3
		) {
			edges {
				node {
					__typename
					id
					slug
					title
					cardDisplayImage {
						gatsbyImageData(placeholder: BLURRED)
						alt
					}
					category {
						categoryName
						categoryColour
					}
					meta {
						firstPublishedAt(formatString: "DD MMMM, YYYY")
					}
				}
			}
		}
	}
`
