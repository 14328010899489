import { FC } from 'react'
import { Anchor } from '../Anchor'
import { AnchorButton } from '../Anchor/AnchorButton'
import { Button } from '../Button'
import { ShareItem } from '../NewsShareBox'

export const NewsButtons: FC<{
	slug: string
	location: Location
	title: string
	share: string
}> = ({ slug, location, title, share }) => {
	const scrollTop = () => {
		return document
			?.querySelector(`body`)
			?.scrollTo({ top: 0, left: 0, behavior: `smooth` })
	}
	return (
		<div className="max-w-2xl mx-auto w-11/12 mt-8 lg:mt-0">
			<div className="bg-primary-slate-100 p-6 rounded-xl lg:hidden">
				<h4 className="font-display text-2xl mb-8">Share Article</h4>
				<div className="grid grid-cols-4 gap-6">
					{JSON.parse(share).length > 0
						? JSON.parse(share).map((item: string) => (
								<div
									key={item}
									className="bg-white rounded-lg flex items-center justify-center p-3 shadow-sm"
								>
									<ShareItem
										{...{
											location,
											title,
											name: item,
											className: `w-full h-full max-h-[25px] max-w-[25px] w-[25px] h-[25px]`,
										}}
									/>
								</div>
						  ))
						: null}
				</div>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-10 xl:gap-16 my-16">
				<Anchor.Button
					{...{
						to: `/news`,
						isSamePageElement: false,
						withArrow: true,
						external: false,
						className: `bg-gradient-sunrise`,
					}}
				>
					Back to News
				</Anchor.Button>

				<Button.Core
					{...{
						className: `bg-gradient-coral`,
						onClick: () => scrollTop(),
					}}
				>
					Back to Top
				</Button.Core>
			</div>
		</div>
	)
}
