import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FC } from 'react'
import { BrandLine } from '../BrandLine'
import Icon from '../Icon'

export const AuthorBanner: FC = ({
	name,
	roleTitle,
	authorBio,
	image,
	publishedAt,
}) => {
	const _image = getImage(image.gatsbyImageData)

	return (
		<div className="bg-primary-slate-100">
			<div className="grid grid-cols-1 md:grid-cols-2 gap-10 mx-auto max-w-6xl py-24 w-11/12">
				<div>
					<div className="flex flex-row item-center justify-center gap-6">
						<GatsbyImage
							image={_image}
							className="rounded-full h-fit"
							alt="something"
						/>
						<div className="flex flex-col items-start justify-center">
							<span className="flex flex-row justify-between items-center gap-16">
								<h3 className="font-display uppercase text-2xl font-extrabold text-primary-slate">
									{name}
								</h3>
							</span>
							<div className="flex flex-row flex-nowrap relative w-full my-2">
								<span className="w-[25%] bg-primary-blue-500 h-2 block" />
								<span className="w-[25%] bg-primary-blue-300 h-2 block" />
								<span className="w-[25%] bg-primary-orange-300 h-2 block" />
								<span className="w-[25%] bg-primary-red h-2 block" />
							</div>
							<p className="mb-2">{roleTitle}</p>
							{publishedAt && <p className="text-sm">Published: {publishedAt}</p>}
						</div>
					</div>
				</div>
				<p>{authorBio}</p>
			</div>
		</div>
	)
}
