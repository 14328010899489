import { useShareState } from '@/context'
import { useWindowSize } from '@/hooks/useWindowSize'
import { copyTextToClipboard } from '@/app-utils/copyToClipboard'
import cx from 'classnames'
import { FC, useLayoutEffect, useRef, useState } from 'react'
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share'
import Icon from '../Icon'

export const ShareItem: FC<{
	name: string
	location: Location
	title: string
	className?: string
}> = ({ name, location, title, className }) => {
	switch (name) {
		case `clipboard`:
			return (
				<div
					className={cx(`cursor-pointer z-10`, className)}
					onClick={() => copyTextToClipboard(location.href)}
				>
					<Icon name={name} />
				</div>
			)
		case `facebook`:
			return (
				<FacebookShareButton
					url={location.href}
					quote={title}
					className={cx(``, className)}
				>
					<Icon name={name} />
				</FacebookShareButton>
			)
		case `linkedin`:
			return (
				<a
					href={`https://www.linkedin.com/sharing/share-offsite/?url=${location.href}`}
					target="_blank"
					rel="noreferrer"
					className={cx(``, className)}
				>
					<Icon name={name} />
				</a>
			)
		case `twitter`:
			return (
				<TwitterShareButton
					title={title}
					related={[`bandainamcoeu`]}
					hashtags={[`bnm`, `share`]}
					url={location.href}
					className={cx(``, className)}
				>
					<Icon name={name} />
				</TwitterShareButton>
			)
		default:
			return false
	}
}

export const NewsShareBox: FC<{
	share: string
	location: Location
	title: string
}> = ({ share, location, title }) => {
	const { show } = useShareState()
	const [width] = useWindowSize()
	const div = useRef<HTMLDivElement | null>(null)
	const isBrowser =
		typeof window !== `undefined` && typeof document !== `undefined`
	const [position, setPosition] = useState<number>(0)

	useLayoutEffect(() => {
		const track = document.querySelector(`.track`)
		const trackingRect = track?.getBoundingClientRect()
		setPosition(trackingRect ? width - trackingRect.right : 0)

		return () => setPosition(trackingRect ? width - trackingRect.right : 0)
	}, [width])
	return (
		<div
			ref={div}
			className={cx(
				`fixed p-6 bg-primary-slate-100 shadow-md text-primary-slate w-[25%] max-w-[350px] top-36 rounded-xl transition-all duration-700 delay-100 hidden lg:block`,
				{
					'lg:opacity-100': show?.display,
					'lg:opacity-0': !show?.display,
				}
			)}
			style={{
				right: position > 0 ? position / 1.5 : 4,
			}}
		>
			<h2 className="font-nexa font-semibold text-2xl mb-8">Share Article</h2>
			<div className="grid grid-cols-4 gap-3">
				{JSON.parse(share).length > 0
					? JSON.parse(share).map((item: string) => (
							<div
								key={item}
								className="bg-white rounded-lg flex items-center justify-center p-3 shadow-sm"
							>
								<ShareItem
									{...{
										location,
										title,
										name: item,
										className: `w-full h-full max-h-[25px] max-w-[25px] w-[25px] h-[25px]`,
									}}
								/>
							</div>
					  ))
					: null}
			</div>
		</div>
	)
}
